body {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  text-decoration: unset;
  color: inherit;
}

h1 {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  unicode-bidi: isolate;
}

img {
  color: inherit;
  font-size: 12px;
  font-family: inherit;
}

@font-face {
  font-family: 'Poppins';
  font-display: swap;
  font-weight: 400;
  src: local('Poppins Regular'), url('../public/fonts/Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-display: swap;
  font-weight: 500;
  src: local('Poppins'), url('../public/fonts/Poppins-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-display: swap;
  font-weight: 600;
  src: local('Poppins SemiBold'), url('../public/fonts/Poppins-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-display: swap;
  font-weight: 700;
  src: local('Poppins Bold'), url('../public/fonts/Poppins-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-display: swap;
  font-weight: 800;
  src: local('Poppins ExtraBold'), url('../public/fonts/Poppins-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Material Icons Round';
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  src: local('Material Icons Round'),
    url('../public/fonts/MaterialIconsRound-Regular.otf') format('opentype');
}

.material-icons-round {
  font-family: 'Material Icons Round';
  font-style: normal;
  font-size: 12px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

.material-icons-round {
  font-size: 12px;
}

.material-icons-round.medium {
  font-size: 16px;
}

.material-icons-round.large {
  font-size: 18px;
}

.material-icons-round.xlarge {
  font-size: 20px;
}

.clicable {
  cursor: pointer;
}

.image-container {
  display: flex;
}
