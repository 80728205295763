.app {
  text-align: center;
  min-height: 100vh;
}

.app-background {
  background-color: #ffffff;
  min-height: 100vh;
  font-size: 14px;
}
