.footer {
  overflow: hidden;
  background-color: #ffffff;
  padding: 18px 10px;
  box-sizing: border-box;
  text-align: center;
}

.footer__rrss {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 0 8px;
}

.footer__rrss-icon {
  padding: 8px;
  box-sizing: border-box;
}

.footer__copyright {
  padding: 8px 0;
  box-sizing: border-box;
}

.footer__copyright span {
  font-size: 10px;
}
