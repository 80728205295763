.header {
  overflow: hidden;
  background-color: #ffffff;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
}

.header__logo {
  display: flex;
  align-items: center;
  color: transparent;
}

.header__logo__image {
  width: 200px;
  height: auto;
}

.header__menu-container {
  display: flex;
  align-items: center;
}
