.not-found {
  padding: 10px;
  box-sizing: border-box;
}

.not-found__title h1 {
  color: #bfb0c5;
  font-weight: 800;
}

@media (min-width: 600px) {
  .not-found__section__text {
    max-width: 80vw;
  }
}

@media (min-width: 900px) {
  .not-found__title h1 {
    font-size: 60px;
  }

  .not-found p {
    font-size: 18px;
  }

  .not-found__section__text {
    max-width: 50vw;
  }
}

@media (min-width: 1200px) {
  .not-found__title h1 {
    font-size: 50px;
  }

  .not-found__section__text {
    max-width: 30vw;
  }
}

@media (min-width: 1400px) {
  .not-found__title h1 {
    font-size: 80px;
  }
}
