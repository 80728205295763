.rrss-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rrss-item__icon {
  width: 16px;
  height: 16px;
}

.rrss-item:hover {
  cursor: pointer;
}

.rrss-item:hover > .rrss-item__icon:hover > g > path {
  fill: #bfb0c5;
}
