.landing {
  display: flex;
  flex-direction: column;
}

.landing__image {
  width: 100vw;
  height: auto;
  pointer-events: none;
  -webkit-filter: grayscale(100%);
  /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.landing__image-container {
  display: flex;
  flex-direction: column;
}

.image-container:hover > img {
  -webkit-filter: unset;
  /* Safari 6.0 - 9.0 */
  filter: unset;
}

@media screen and (min-width: 1200px) {
  .landing__image-container {
    flex-direction: row;
  }

  .landing__image-container .landing__image {
    width: 50vw;
    height: auto;
  }
}
