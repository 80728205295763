.about {
  padding: 10px 0 0 0;
  box-sizing: border-box;
}

.about__title h1 {
  color: #bfb0c5;
  font-weight: 800;
}

.about__title.mobile {
  display: block;
}

.about__title.mobile h1 {
  font-size: 30px;
}

.about__title.desktop {
  display: none;
}

.about__section {
  display: flex;
  flex-direction: column;
}

.about__section__text {
  text-align: start;
  padding: 20px 30px;
  box-sizing: border-box;
}

.about__section__image > img {
  width: 100vw;
  height: auto;
  pointer-events: none;
}

@media (min-width: 600px) {
  .about__title.mobile {
    padding: 10px 80px 0;
  }

  .about__section__text {
    padding: 20px 80px;
  }

  .about__section.portrait .about__section__image > img {
    width: auto;
    height: 80vw;
  }
}

@media (min-width: 900px) {
  .about__title.desktop {
    display: block;
    text-align: left;
  }

  .about__title.mobile {
    display: none;
  }

  .about__title.desktop h1 {
    font-size: 60px;
    letter-spacing: 20px;
  }

  .about__section {
    flex-direction: row;
    padding: 20px 0;
    box-sizing: border-box;
    align-items: center;
  }

  .about__section.portrait.reverse {
    align-items: flex-start;
  }

  .about__section.portrait.reverse .about__section__texts {
    margin-top: 10vh;
  }

  .about__section.reverse {
    flex-direction: row-reverse;
  }

  .about__section .about__section__image > img {
    width: 50vw;
  }

  .about__section.portrait .about__section__image > img {
    height: 100vh;
    width: auto;
  }

  .about__title.desktop,
  .about__section__text {
    padding: 0 80px;
  }
}

@media (min-width: 1200px) {
  .about__title.desktop,
  .about__section__text {
    padding: 0 10%;
    font-size: 1.5vw;
  }
}

@media (min-width: 1400px) {
  .about__title.desktop h1 {
    font-size: 80px;
  }

  .about__section {
    height: 100vh;
    width: auto;
  }

  .about__section__text,
  .about__section__image {
    width: 50vw;
    height: auto;
  }

  .about__section__text {
    font-size: 1vw;
  }
}

@media (min-width: 1800px) {
  .about__section__text {
    font-size: 1vw;
  }
}
