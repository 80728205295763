.header__menu {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.header__menu-item,
.header__menu-item--active {
  padding: 8px;
  margin: 0 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
}

.header__menu-item--active {
  background-color: #bfb0c5;
  font-weight: 600;
}

.header__rrss {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  box-sizing: border-box;
  gap: 0 16px;
}

.header__menu-item:hover {
  color: #bfb0c5;
  font-weight: 500;
}

@media (min-width: 600px) {
  .header__menu {
    flex-direction: row;
  }

  .header__menu-item--active {
    background-color: unset;
    border-bottom: 2px solid #bfb0c5;
  }

  .header__rrss {
    padding: 0 16px;
  }
}

/* Mobile */
.header__menu-list-icon {
  padding: 0 8px;
  box-sizing: border-box;
}

.header__menu-list {
  position: absolute;
  width: 100%;
  height: auto;
  top: 46px;
  left: 0;
  z-index: 1;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  box-sizing: border-box;
}

.header__menu-list__background {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  height: 100%;
  width: 100%;
  opacity: 100%;
}
